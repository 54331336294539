<template>
	<div
		class="top-line-primary"
	>
		<div
			class="mt-30 width-480-pc ma-auto flex-column full-height overflow-y-auto  box box-shadow"
		>
			<div>
				<div class="justify-space-between bg-base">
					<h6 class="pa-10 ">내 정보</h6>
				</div>
				<ul
					class="box-mypage-list shadow ul-under-line size-px-14 "
				>
					<li
						class="pa-10 justify-space-between"
					>
						<span>아이디</span>
						<span>{{ user.member_id }}</span>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>이름</span>
						<span>{{ user.member_name }}</span>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>연락처</span>
						<span>{{ user.member_tell }} <button class="btn-primary" @click="onChange">변경하기</button></span>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>이메일</span>
						<span>
							<input
								v-model="item.member_email"
								placeholder="이메일을 입력하세요"
								class="input-box-inline text-right"
								:rules="[$rules.max(item, 'member_email', 50)]"
							/></span>
					</li>
					<li
						class="pa-10 justify-space-between items-center"
					>
						<span>기본 주소</span>
						<span class="">
							<button
								class="bg-identify pa-5-10 size-px-12-m"
								@click="daumPost('default')"
							>주소 검색</button>
						</span>
					</li>
					<li class="pa-10 text-right">
						<span>
							<input
								v-model="item.member_post"
								type="number" placeholder="우편번호"
								class="input-box-inline text-right"
								readonly

								:rules="[$rules.numeric(item, 'member_post', 10)]"
							/>
						</span>
					</li>
					<li class="pa-10 " >
						<div class=" text-right">
							<input
								v-model="item.member_addr1"
								type="text" placeholder="기본 주소"
								class="input-box-inline width-90 text-right"
								readonly
								maxlength="80"
							/>
						</div>
					</li>
					<li class="pa-10 " >
						<div class=" text-right">
							<input
								v-model="item.member_addr2"
								type="text" placeholder="상세 주소"
								class="input-box-inline width-90 text-right"
								maxlength="80"
							/>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div
			class="mt-30 text-center"
		>
			<button
				class="btn btn-fill"
				@click="save"
			>저장</button>
		</div>

		<DaumPost
			:overlay="daumPostUp"
			@callBack="addPost"
		></DaumPost>

		<PopupConfirm
			v-if="is_on_change"

			@cancel="is_on_change = false"
			@click="onConfirm"
		>
			<template
				v-slot:title
			>연락처 변경</template>
			<template
				v-slot:main-txt
			>본인명의의 휴대폰으로만 변경 가능합니다.</template>

			<template
				v-slot:name-confirm
			>본인 인증</template>
		</PopupConfirm>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import PopupConfirm from "../Layout/PopupConfirm";

export default{
	name: 'Modify'
	,props: ['user']
	,components: {PopupConfirm, DaumPost }
	,data: function(){
		return {
			program: {
				name: '정보수정'
				,code: 'mypage'
				,wrap: 'mypage'
				,top: false
				,title: true
				,bottom: true
				,class: 'blue'
				, top_line: true
			}
			,daumPostUp: false
			,item: {
				member_tell: this.user.member_tell
				, member_email: this.user.member_email
				, member_post: this.user.member_post
				, member_addr1: this.user.member_addr1
				, member_addr2: this.user.member_addr2
			}
			,view: {
				password: false
			}
			, is_on_change: false
		}
	}
	,methods: {
		save: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: '/member/postModify'
					,data: this.item
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,setNotify: function({ type, message }){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		, daumPost: function (type) {
			this.$emit('setOverlay')
			this.daumPostUp = true
			this.daumPostType = type
		}
		, addPost: function (call) {

			this.$set(this.item, 'member_post', call.zonecode)
			this.$set(this.item, 'member_addr1', call.address)

			this.daumPostUp = false
			this.$emit('setOverlay')
		}
		, onChange: function(){
			this.is_on_change = true
		}
		, onConfirm: function(){

			window.removeEventListener('message', this.niceResult)
			setTimeout( () => {
				window.addEventListener('message', this.niceResult)
			}, 100)

			let baseUrl = this.$baseUrl
			let type = 'change'

			this.nice_url = baseUrl + '/nice/request?type=' + type + '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')

			window.open(this.nice_url, 'nice', 'width=320px')
		}
		, niceResult: function(e){
			console.log('niceResult', e.data)
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				domains.push('localhost')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					if(data && typeof data == 'string'){
						data = JSON.parse(data)

						if(data.type == 'nice_result'){
							if(data.result){
								this.is_on_change = false
								this.$bus.$emit('notify', { type: 'success', message: '정상적으로 처리되었습니다'})
								this.$bus.$emit('setUser', [{ key: 'member_tell', val: data.member_tell}])
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				// this.is_on_confirm = false
			}
		}
		, getConfirm: function(){

			setTimeout( () => {
				if(this.user.member_id && this.user.last_confirm != 1){
					this.is_on_confirm = true
				}
			}, 100)
		}
	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
	,watch: {
	}
}
</script>

<style>
.box-mypage-list {}
.box-mypage-list.shadow { box-shadow: 0px 3px 5px rgb(0, 0, 0, .3) }
.btn-mypage-add {
	border: 1px solid #ddd;
	padding: 2px 2px 2px 7px;
	font-size: 11px
}

.box-mypage-list li {
	align-items: center;
}
</style>